import { useCallback } from 'react';
import { useAxios } from '@/hooks/useAxios';
import { OrganizationTreePayloadType, SearchPayloadType } from '@/utils/types';

export const useOrganizationService = () => {
	const { get, isLoading } = useAxios();

	const getOrganizationsTree = useCallback(
		(query?: OrganizationTreePayloadType) => get('/organizations/tree', query),
		[get],
	);
	const searchOrganizations = useCallback(
		(query: SearchPayloadType) => get('/organizations/search', query),
		[get],
	);

	const gpsProgress = useCallback(() => get('/organizations/gps-progress'), [get]);

	return {
		getOrganizationsTree,
		searchOrganizations,
		gpsProgress,
		isLoadingOrganizations: isLoading,
	};
};
